import { IChain } from './type'

interface IServerDataObj {
  baseURL: string
  questBaseURL: string
  webCarouselBaseURL: string
  launchPadBaseURL: string
  marketplaceBaseURL: string
  logInUrl: string
  resetPasswordURL: string
  requestForgotPasswordOtp: string
  verifyPasswordRestOtpUrl: string
  getGamePlayers: string
  getPlayerDetails: string
  blockUser: string
  banUser: string
  getAllCategories: string
  getPreSignedS3: string
  getPreSignedS3Quest: string
  getLaunchpadPreSignedS3: string
  getLaunchpadPreSignedS3New: string
  applyForLaunchpad: string
  categoryUrl: string
  categorySettingsUrl: string
  platformFeesUrl: string
  getPlayersCountUrl: string
  getDeletionRequest: string
  getDeletionRequestsReviews: string
  getDeletionRequestReview: string
  getUnblockedGamePlayers: string
  getBlockedGamePlayers: string
  getAllNotificationSetting: string
  notificationSettingUrl: string
  faqUrl: string
  faqsUrl: string
  contactDetailsUrl: string
  termsUrl: string
  termsVersion: string
  assetsUrl: string
  gamesUrl: string
  reviewUrl: string
  getGamesCountUrl: string
  activityUrl: string
  gameUrl: string
  filterGame: string
  toggleGameStatusUrl: string
  getAssetUrl: string
  activateAssetUrl: string
  getAllLaunchpads: string
  getLaunchpadsByFilter: string
  postStarterPack: string
  approveLaunchpad: string
  rejectLaunchpad: string
  launchpadCount: string
  verifyLaunchpadUrl: string
  verifyLaunchpadAdminUrl: string
  updateLaunchpadAsBannerUrl: string
  gameBaseUrl: string
  solanaExplorerUrl: string
  ethereumExplorerUrl: string
  getAssetDetails: string
  featuredGames: string
  topGames: string
  featuredGamesSettings: string
  getPublishers: string
  gamePublishers: string
  editPublisher: string
  gamePublisherCount: string
  gamePublisher: string
  gamePublisherStatus: string
  gamePublisherDetailsTabCount: string
  dashboardCharts: string
  dashboardStats: string
  kbaUrl: string
  blogsUrl: string
  blogUrl: string
  blogSectionUrl: string
  cryptoRates: string
  getGlossariesUrl: string
  glossaryUrl: string
  totalSales: string
  activeUsers: string
  activePublishers: string
  getComments: string
  deleteReview: string
  patchReview: string
  getBrowserBasedGames: string
  updateBrowserBasedGame: string
  BrowserBasedGamePublishers: string
  BrowserBasedGameTags: string
  getStarterPackList: string
  getStarterpackStats: string
  BrowserBasedGameGenre: string
  getSubscriptionsList: string
  BrowserBasedGameActivity: string
  BrowserBasedGameTimePlayed: string
  updateSubscriptionsStatus: string
  getSubscription: string
  updateSubscription: string
  createSubscription: string
  deleteSubscription: string
  getFeatures: string
  configureFeatures: string
  getStarterPackModule: string
  maxClaimedLimits: string
  updateMaxClaimedLimits: string
  subAdmin: string
  activateStarterPack: string
  submitModules: string
  downloadTotalSales: string
  downloadActiveUsers: string
  downloadActivePublishers: string
  socialConnects: string
  socialConnectItem: string
  assetVisibility: string
  getWallet: string
  updateWallet: string
  getTransactionLogs: string
  getQuest: string
  getQuestData: string
  getQuestReport: string
  downloadQuestReport: string
  getQuestDetail: string
  getQuestStat: string
  getQuestDetailsData: string
  getQuestDetailsLogicGroupData: string
  getQuestDetailsRewardsData: string
  getQuestDetailsClaimedRewardsData: string
  getQuestStatsData: string
  getQuestParticipantsData: string
  getEventData: string
  getCategoriesLevel: string
  getCategories: string
  getEventCategories: string
  postEventCategories: string
  getLogicGroup: string
  createCategoryLevel: string
  postQuestBasicInfo: string
  postLogicGroupId: string
  updateCategoryLevel: string
  updateCategory: string
  downloadTransactionLogs: string
  cryptoCurrency: string
  createLogicGroup: string
  updateLogicGroup: string
  updateQuestEvent: string
  questTabs: string
  getStarterPack: string
  postRewards: string
  getQuestDetailsMissionData: string
  getQuestDetailsLogicGroupsData: string
  getLogicGroupEventType: string
  getQuestDraft: string
  getDigitalAssets: string
  postPhysicalAwards: string
  getContextualInfo: string
  getQuestRequestChanges: string
  getPhysicalAward: string
  profileData: string
  category: string
  getViewRewardsList: string
  marketplace: string
  commonMarketplace: string
  userType: string
  logicGroupRequest: string
  logicGroupStatus: string
  configMarketplaces: string
  configMarketplace: string
  analyticsIntegration: string
  configWebCarousel: string
  configFloatCarousel: string
  featureAsset: string
  gameStats: string
  gameOfferReceived: string
  activeListing: string
  userActivityLogs: string
  userActivityParticipants: string
  featureQuest: string
  gameQuest: string
  getIgdbGameDetails: string
  getBrowserBasedGamesCategoryList: string
  getLanguageList: string
  getGamePlatformList: string
  getGameModeList: string
  addBrowserBasedGame: string
  getGameOverview: string
  getPlayModes: string
  getBrowserSupport: string
  webCarousel: string
  floatingCarousel: string
  getIgdbGameSlug: string
  getGameRequest: string
  audienceCatChart: string
  walletAssetOwnedChart: string
  getTrxByPricePointGraph: string
  getRevenueChartData: string
  getTransactionChartData: string
  getTransactionAvgChartData: string
  updateTrxPricePointSlots: string
  getTopAssetChart: string
  getUserTrxBehavior: string
  analyticsStatsCharts: string
  getQuestStats: string
  getQuestLogicGroupChart: string
  getSocialInteractionChart: string
  getAllQuest: string
  getAllAdminSubAdmins: string
  getSocialEngagementChart: string
  updateWalletChartSlotsRange: string
  getGameRequestChange: string
  updateGameRequestStatus: string
  getCompanies: string
  gameRequestDetails: string
  getAvailableName: string
  getUserReport: string
  getActivityReport: string
  getActivityReportConfigure: string
  updateActivityReportConfigure: string
  getUserReportConfigure: string
  updateUserReportConfigure: string
  getActivityReportConfigurePublisher: string
  updateActivityReportConfigurePublisher: string
  getUserReportConfigurePublisher: string
  updateUserReportConfigurePublisher: string
  userMktFeBaseUrl: string
  configPublisher: string
  gamePublisherNames: string
  getEventsByQuestLogicGroupId: string
  getUsersByLogicGroupId: string
  getLogicGroupDetailsInQuest: string
  getCompanyPublishers: string
  getCompanyRequest: string
  getCompanyRequestChanges: string
  getCompanyRequestStatus: string
  accountDeleteContent: string
}

const baseServerEndpoints = {
  logInUrl: `api/v1/admin/auth/login`,
  resetPasswordURL: `api/v1/admin/auth/reset-password`,
  requestForgotPasswordOtp: `api/v1/admin/auth/send-verification-otp`,
  verifyPasswordRestOtpUrl: `api/v1/admin/auth/confirm-verification-otp`,
  getGamePlayers: `api/v1/admin/users`,
  getPlayerDetails: `api/v1/admin/user`,
  getAllCategories: `api/v1/admin/categories`,
  blockUser: `api/v1/admin/block-user`,
  getDeletionRequest: `api/v1/admin/user/account-deletion-requests`,
  getDeletionRequestsReviews: `api/v1/admin/user/account-deletion-requests-reviews`,
  getDeletionRequestReview: `api/v1/admin/user/account-deletion-request-reviews`,
  banUser: `api/v1/admin/ban-user`,
  getPreSignedS3: `api/v1/common/upload/get-presigned-url`,
  getPreSignedS3Quest: `api/v1/admin/upload/get-presigned-url`,
  getLaunchpadPreSignedS3: `api/v1/s3/pre-signed-urls`,
  getLaunchpadPreSignedS3New: `api/v1/s3/pre-signed-image-urls`,
  applyForLaunchpad: `api/v1/launchpad/apply`,
  categoryUrl: `api/v1/admin/category`,
  categorySettingsUrl: `api/v1/admin/category/settings`,
  postStarterPack: `api/v1/starterpack/create-module`,
  platformFeesUrl: `api/v1/admin/platform-fee`,
  getPlayersCountUrl: `api/v1/admin/get-users-count`,
  getUnblockedGamePlayers: `api/v1/admin/unblocked-users`,
  getBlockedGamePlayers: `api/v1/admin/blocked-users`,
  getAllNotificationSetting: `api/v1/admin/notifications`,
  notificationSettingUrl: `api/v1/admin/notification`,
  faqsUrl: `api/v1/admin/faqs`,
  getQuestStat: 'api/v1/admin/quests/user-details',
  faqUrl: `api/v1/admin/faq`,
  contactDetailsUrl: `api/v1/admin/contact-us`,
  termsUrl: `api/v1/admin/terms-and-conditions`,
  termsVersion: `api/v1/admin/terms-and-conditions-by-version`,
  assetsUrl: `api/v1/common/assets`,
  gamesUrl: `api/v1/common/games`,
  getGamesCountUrl: `api/v1/common/games/count`,
  gameUrl: `api/v1/common/game`,
  filterGame: `api/v1/filter/games`,
  activityUrl: `api/v1/common/activity`,
  toggleGameStatusUrl: `api/v1/common/game/activate`,
  getAssetUrl: `api/v1/common/asset`,
  activateAssetUrl: `api/v1/admin/asset/activate`,
  getAllLaunchpads: `api/v1/launchpad/all`,
  getLaunchpadsByFilter: `api/v1/launchpad/by-filter`,
  approveLaunchpad: `api/v1/launchpad/approve`,
  rejectLaunchpad: `api/v1/launchpad/reject`,
  launchpadCount: `api/v1/launchpad/counts`,
  verifyLaunchpadUrl: `api/v1/launchpad/verify`,
  verifyLaunchpadAdminUrl: `api/v1/launchpad/verify-by-admin`,
  updateLaunchpadAsBannerUrl: `api/v1/launchpad/update-banner`,
  getAssetDetails: `api/v1/assets`,
  featuredGames: `api/v1/admin/featured-games`,
  topGames: `api/v1/admin/top-games`,
  featuredGamesSettings: `api/v1/admin/featured-games/settings`,
  getPublishers: `api/v1/admin/publisher-names`,
  gamePublishers: `api/v1/admin/publishers`,
  editPublisher: `api/v1/admin/publisher`,
  gamePublisherCount: `api/v1/admin/publisher/count`,
  gamePublisher: `api/v1/admin/publisher`,
  gamePublisherStatus: `api/v1/admin/publisher/status`,
  gamePublisherDetailsTabCount: `api/v1/admin/publisher/publisher-details/tab-count`,
  dashboardCharts: `api/v1/admin/dashboard/chart`,
  dashboardStats: `api/v1/admin/dashboard/stats`,
  kbaUrl: `api/v1/admin/kba`,
  blogsUrl: `api/v1/admin/blogs`,
  blogUrl: `api/v1/admin/blog`,
  blogSectionUrl: `api/v1/admin/blog-section`,
  cryptoRates: `api/v1/crypto-currency`,
  getGlossariesUrl: `api/v1/admin/glossaries`,
  glossaryUrl: `api/v1/admin/glossary`,
  totalSales: `api/v1/admin/total-sales`,
  activeUsers: `api/v1/admin/active-users`,
  activePublishers: `api/v1/admin/active-publishers`,
  getComments: `api/v1/game/comment`,
  reviewUrl: `api/v1/admin/comment/get_all`,
  deleteReview: `api/v1/admin/comment/`,
  patchReview: `api/v1/admin/comment/`,
  getBrowserBasedGames: 'api/v1/admin/browser-game',
  updateBrowserBasedGame: 'api/v1/admin/update/browser-game',
  BrowserBasedGamePublishers: 'api/v1/admin/get-publisher/browser-game',
  BrowserBasedGameTags: 'api/v1/admin/get-tags/browser-game',
  getStarterPackList: `api/v1/starterpack/by-filter`,
  getStarterpackStats: `api/v1/starterpack/statistics`,
  BrowserBasedGameGenre: 'api/v1/admin/genre/browser-game',
  getSubscriptionsList: `api/v1/admin/get-product`,
  BrowserBasedGameActivity: 'api/v1/admin/get/browser-game-activity',
  BrowserBasedGameTimePlayed: 'api/v1/admin/get/browser-game-timeplayed',
  updateSubscriptionsStatus: `api/v1/admin/change-product-status`,
  getSubscription: `api/v1/admin/get-product-by-id`,
  updateSubscription: `api/v1/admin/update-product`,
  createSubscription: `api/v1/admin/add-product`,
  deleteSubscription: `api/v1/admin/delete-product`,
  getFeatures: 'api/v1/admin/get-features',
  configureFeatures: 'api/v1/admin/configure-features',
  getStarterPackModule: 'api/v1/starterpack/created-by-filter',
  maxClaimedLimits: 'api/v1/starterpack/max-claim-limit',
  updateMaxClaimedLimits: 'api/v1/starterpack/updateMaxClaimLimit',
  subAdmin: 'api/v1/admin/sub-admin/',
  activateStarterPack: `api/v1/starterpack/updateStarterPackModuleStatus`,
  submitModules: `api/v1/starterpack/updateMaxModulesToShow`,
  downloadTotalSales: `api/v1/admin/download-sales`,
  downloadActiveUsers: `api/v1/admin/get-active-users`,
  downloadActivePublishers: `api/v1/admin/get-active-publisher`,
  socialConnects: `api/v1/admin/social-connects`,
  socialConnectItem: `api/v1/admin/social-connect`,
  assetVisibility: `api/v1/admin/marketplace/config`,
  getWallet: 'api/v1/admin/wallet-config',
  updateWallet: 'api/v1/admin/wallet-config',
  getTransactionLogs: 'api/v1/admin/transaction-logs',
  getQuest: `api/v1/admin/quest`,
  getQuestData: `api/v1/admin/quests`,
  getQuestReport: `api/v1/admin/user-quest-report`,
  getQuestDetail: `api/v1/admin/user-quest-details/`,
  downloadQuestReport: `api/v1/admin/quest-report`,
  getQuestDetailsData: `api/v1/admin/quests/details`,
  getQuestDetailsLogicGroupData: `api/v1/admin/quest/logic-groups`,
  getQuestDetailsRewardsData: `api/v1/admin/quest/awards`,
  getQuestDetailsClaimedRewardsData: `api/v1/admin/quests/claimed-awards`,
  getQuestStatsData: `api/v1/admin/quest/stats`,
  getQuestParticipantsData: `api/v1/admin/quests/participants`,
  getEventData: `api/v1/admin/events`,
  getCategoriesLevel: `api/v1/admin/event-category-levels`,
  getCategories: `api/v1/categories`,
  getEventCategories: `api/v1/admin/event-category`,
  postEventCategories: `api/v1/admin/create-event-category`,
  getLogicGroup: `api/v1/admin/logic-group`,
  createCategoryLevel: `api/v1/admin/create_new_event_category_level`,
  postQuestBasicInfo: `api/v1/admin/quest/basic-info`,
  postLogicGroupId: `api/v1/admin/quest/logic-group`,
  updateCategoryLevel: `api/v1/admin/update-event-category-levels`,
  updateCategory: `api/v1/admin/update-event-category`,
  downloadTransactionLogs: 'api/v1/admin/download/transaction-logs',
  cryptoCurrency: 'api/v1/crypto-currency',
  createLogicGroup: `api/v1/admin/create-logic-group`,
  updateLogicGroup: `api/v1/admin/update-logic-group`,
  updateQuestEvent: `api/v1/admin/event`,
  questTabs: 'api/v1/admin/quest-tabs',
  getStarterPack: 'api/v1/admin/starter-packs',
  postRewards: 'api/v1/admin/quest/rewards',
  getQuestDetailsMissionData: 'api/v1/admin/quest/logic-groups',
  getQuestDetailsLogicGroupsData: 'api/v1/admin/quest/me/logic-groups',
  getLogicGroupEventType: 'api/v1/admin/logic-group-event-types',
  getQuestDraft: `api/v1/admin/quest-draft`,
  getDigitalAssets: `api/v1/admin/digital-awards`,
  postPhysicalAwards: `api/v1/admin/physical-award`,
  getContextualInfo: 'api/v1/admin/quests/contextual-info',
  getQuestRequestChanges: 'api/v1/admin/quests/change-request',
  getPhysicalAward: `api/v1/admin/physical-award`,
  profileData: `api/v1/profile/data`,
  category: `api/v1/admin/event-category`,
  getViewRewardsList: `api/v1/admin/view-rewards`,
  marketplace: `api/v1/admin/marketplace/`,
  commonMarketplace: `api/v1/admin/marketplace`,
  userType: `api/v1/admin/user-type/`,
  logicGroupRequest: `api/v1/admin/logic-group-requests`,
  logicGroupStatus: `api/v1/admin/logic-group-requests/change-status`,
  configMarketplaces: `api/v1/admin/config/marketplaces`,
  configMarketplace: `api/v1/admin/config/marketplace`,
  analyticsIntegration: `api/v1/admin/config/analytics-integration`,
  configWebCarousel: `api/v1/config/web-carousel`,
  configFloatCarousel: `api/v1/config/floating-carousel`,
  featureAsset: 'api/v1/common/asset/featured',
  gameStats: `api/v1/common/game/stats/`,
  gameOfferReceived: `api/v1/common/game/offers`,
  activeListing: `api/v1/common/game/active-listings`,
  userActivityLogs: `api/v1/common/game-logs`,
  userActivityParticipants: `api/v1/common/game-participants`,
  featureQuest: `api/v1/admin/quest/feature`,
  gameQuest: `api/v1/users/quests`,
  getIgdbGameDetails: `api/v1/load-igdb-game`,
  getBrowserBasedGamesCategoryList: `api/v1/common/game-genres`,
  getLanguageList: `api/v1/common/game-languages`,
  getGameModeList: `api/v1/common/game-modes`,
  getGamePlatformList: `api/v1/common/game-platforms`,
  addBrowserBasedGame: `api/v1/common/edit-game`,
  getGameOverview: `api/v1/common/game-overview`,
  getPlayModes: `api/v1/common/game-play-modes`,
  getBrowserSupport: `api/v1/common/game-browsers`,
  webCarousel: `api/v1/web-carousel/`,
  floatingCarousel: `api/v1/floating-carousel/`,
  getIgdbGameSlug: `api/v1/check-igdb-game-presence`,
  audienceCatChart: `api/v1/admin/analytics/chart/category-interest`,
  walletAssetOwnedChart: `api/v1/admin/analytics/chart/wallet-make-up`,
  getTopAssetChart: `api/v1/admin/analytics/chart/top_assets`,
  getUserTrxBehavior: `api/v1/admin/analytics/chart/user-txn-behavior`,
  analyticsStatsCharts: `api/v1/admin/analytics/chart/analytics-stats`,
  getQuestStats: `api/v1/admin/analytics/quest-graph`,
  getQuestLogicGroupChart: `api/v1/admin/analytics/quest-stats`,
  getSocialInteractionChart: `api/v1/admin/analytics/chart/social-interaction`,
  getAllQuest: `api/v1/users/quests`,
  getAllAdminSubAdmins: `api/v1/admin`,
  getSocialEngagementChart: `api/v1/admin/analytics/chart/social-engagement`,
  updateWalletChartSlotsRange: `api/v1/admin/analytics/chart/update-slots`,
  getGameRequest: `api/v1/common/game-request-list`,
  getGameRequestChange: `api/v1/common/game-changes/`,
  updateGameRequestStatus: `api/v1/admin/edit-game-request-status`,
  getCompanies: `api/v1/common/publishers/companies`,
  gameRequestDetails: `api/v1/common/game-request-detail`,
  getAvailableName: `api/v1/common/gamename-availability`,
  getUserReport: `api/v1/admin/user-report`,
  getActivityReport: `api/v1/admin/activity-report`,
  getActivityReportConfigure: `api/v1/admin/activity-report-attributes`,
  updateActivityReportConfigure: `api/v1/admin/update-activity-report-attributes`,
  getUserReportConfigure: `api/v1/admin/user-report-attributes`,
  updateUserReportConfigure: `api/v1/admin/update-user-report-attributes`,
  getActivityReportConfigurePublisher: `api/v1/admin/publisher-activity-report-attributes`,
  updateActivityReportConfigurePublisher: `api/v1/admin/update-publisher-activity-report-attributes`,
  getUserReportConfigurePublisher: `api/v1/admin/publisher-user-report-attributes`,
  updateUserReportConfigurePublisher: `api/v1/admin/update-publisher-user-report-attributes`,
  configPublisher: `api/v1/admin/publisher-config-report-data`,
  gamePublisherNames: `api/v1/admin/publisher-names`,
  getEventsByQuestLogicGroupId: `api/v1/admin/analytics/event-details`,
  getUsersByLogicGroupId: `api/v1/admin/analytics/logic-group-user-details`,
  getLogicGroupDetailsInQuest: `api/v1/admin/analytics/logic-group-details`,
  getCompanyPublishers: `api/v1/admin/company/publishers`,
  getCompanyRequest: `api/v1/admin/company_requests`,
  getCompanyRequestChanges: `api/v1/admin/company-changes`,
  getCompanyRequestStatus: `api/v1/admin/company/status`,
  getTrxByPricePointGraph: `api/v1/admin/analytics/chart/price-point`,
  getRevenueChartData: `api/v1/admin/analytics/chart/revenue-graph`,
  getTransactionChartData: `api/v1/admin/analytics/chart/transaction-data`,
  getTransactionAvgChartData: `api/v1/admin/analytics/chart/txn-avg-price-graph`,
  updateTrxPricePointSlots: `api/v1/admin/analytics/chart/price-point-slots`,
  accountDeleteContent: 'api/v1/admin/account-delete-content',
}

export const serverConfig: IServerDataObj = {
  questBaseURL: process.env.REACT_APP_QUEST_BASE_URL as string,
  webCarouselBaseURL: process.env.REACT_APP_WEB_CAROUSEL_BASE_URL as string,
  launchPadBaseURL: process.env.REACT_APP_LAUNCHPAD_BASE_URL as string,
  marketplaceBaseURL: process.env.REACT_APP_MARKETPLACE_BASE_URL as string,
  baseURL: process.env.REACT_APP__BASE_URL as string,
  gameBaseUrl: process.env.REACT_APP_GAME_BASE_URL as string,
  solanaExplorerUrl: process.env.REACT_APP_SOLANA_EXPLORER_URL as string,
  ethereumExplorerUrl: process.env.REACT_APP_ETHEREUM_EXPLORER_URL as string,
  userMktFeBaseUrl: process.env.REACT_APP_FE_BASE_URL as string,
  ...baseServerEndpoints,
}

export const BLOCK_EXPLORE_URLS: IChain = {
  goerli: process.env.REACT_APP_BLOCK_EXPLORE_URL_GOERLI,
  sepolia: process.env.REACT_APP_BLOCK_EXPLORE_URL_SEPOLIA,
  ethereum: process.env.REACT_APP_BLOCK_EXPLORE_URL_ETHEREUM,
  solana: process.env.REACT_APP_BLOCK_EXPLORE_URL_SOLANA_DEVNET,
  polygon: process.env.REACT_APP_BLOCK_EXPLORE_URL_POLYGON,
}

export const columnConfig = [
  {
    title: 'Quest Name',
    accessor: 'col1',
    dataKey: 'quest_name',
    value: 'quest_name',
    sortable: true,
  },
  {
    title: 'Quest Status',
    accessor: 'col2',
    dataKey: 'quest_status',
    value: 'quest_status',
    sortable: true,
  },
  {
    title: 'Quest % Complete',
    accessor: 'col3',
    dataKey: 'quest_percentage_complete',
    value: 'quest_percentage_complete',
    sortable: true,
  },
  {
    title: 'Quest Type',
    accessor: 'col4',
    dataKey: 'quest_type',
    value: 'quest_type',
    sortable: true,
  },
  {
    title: 'Quest Owner',
    accessor: 'col5',
    dataKey: 'quest_owner',
    value: 'quest_owner',
    sortable: true,
  },
  {
    title: 'Quest Start Time',
    accessor: 'col6',
    dataKey: 'quest_start_time',
    value: 'quest_start_time',
    sortable: true,
  },
  {
    title: 'Quest End Time',
    accessor: 'col7',
    dataKey: 'quest_end_time',
    value: 'quest_end_time',
    sortable: true,
  },
  {
    title: 'Quest Category',
    accessor: 'col8',
    dataKey: 'quest_category',
    value: 'quest_category',
    sortable: true,
  },
  {
    title: 'Quest Category Level',
    accessor: 'col9',
    dataKey: 'quest_category_level',
    value: 'quest_category_level',
    sortable: true,
  },
  {
    title: 'Quest Collection',
    accessor: 'col10',
    dataKey: 'quest_collection',
    value: 'quest_collection',
    sortable: true,
  },

  {
    title: 'Quest Logic Group',
    accessor: 'col11',
    dataKey: 'quest_logic_group',
    value: 'quest_logic_group',
    sortable: true,
  },
  {
    title: 'Quest Total Events',
    accessor: 'col12',
    dataKey: 'quest_total_events',
    value: 'quest_total_events',
    sortable: true,
  },

  {
    title: 'Quest XP Points Earned',
    accessor: 'col13',
    dataKey: 'quest_xp_points_earned',
    value: 'quest_xp_points_earned',
    sortable: true,
  },
]

import { handleError } from 'utils/apis'
import { axiosClient } from 'utils/axios'
import { serverConfig } from 'utils/constants'
import { IQuestEventsPayload, IQuestReportFilters } from 'utils/type'

export const GetQuestData = async (payload: any) => {
  try {
    const url = serverConfig.questBaseURL + serverConfig.getQuestData
    const res = await axiosClient.post(url, payload)
    return res.data
  } catch (error: any) {
    handleError(error)
    return false
  }
}
export const GetQuestReport = async (payload: IQuestReportFilters) => {
  const { page, limit, ...rest } = payload
  try {
    // Construct the URL with query parameters for page and limit
    const url = `${serverConfig.questBaseURL}${serverConfig.getQuestReport}?page=${page}&limit=${limit}`

    // Make the POST request with the rest of the payload
    const res = await axiosClient.post(url, rest)
    return res.data
  } catch (error: any) {
    handleError(error)
    return false
  }
}
export const GetQuestDetail = async (id: string) => {
  try {
    const url = serverConfig.questBaseURL + serverConfig.getQuestDetail + id

    const res = await axiosClient.get(url)
    return res?.data
  } catch (error: any) {
    handleError(error)
    return false
  }
}

export const GetQuestStats = async (payload: any) => {
  try {
    const url = serverConfig.questBaseURL + serverConfig.getQuestStat
    const res = await axiosClient.get(url, { params: payload })
    return res?.data
  } catch (error: any) {
    handleError(error)
    return false
  }
}

export const GetQuestTabsCount = async () => {
  try {
    const url = serverConfig.questBaseURL + serverConfig.questTabs
    const res = await axiosClient.get(url)
    return res.data
  } catch (error: any) {
    handleError(error)
    return false
  }
}

export const GetQuestStatsData = async (payload: any) => {
  try {
    const url = serverConfig.questBaseURL + serverConfig.getQuestStatsData
    const res = await axiosClient.get(url, { params: payload })
    return res.data
  } catch (error: any) {
    handleError(error)
    return false
  }
}

export const DeleteQuestById = async (payload: any) => {
  try {
    const url =
      serverConfig.questBaseURL + serverConfig.getQuest + `/${payload.id}`
    const res = await axiosClient.delete(url)

    return res.data
  } catch (error: any) {
    handleError(error)
    return false
  }
}

export const UpdateQuestById = async (payload: {
  action: boolean
  quest_id: string | undefined
  status_type: string
  reason?: string
}) => {
  try {
    const url = serverConfig.questBaseURL + serverConfig.getQuest

    const data = {
      quest_id: payload.quest_id,
      status: {
        action: payload.action,
        status_type: payload.status_type,
        reason: payload.reason,
      },
    }

    const res = await axiosClient.patch(url, data)
    if (res) return true
  } catch (error: any) {
    handleError(error)
    return false
  }
}
export const UpdateFeatureQuest = async (payload: {
  quest_id: string | undefined
  is_featured: boolean
  slug: string
}) => {
  try {
    const url = serverConfig.questBaseURL + serverConfig.featureQuest

    const data = {
      quest_id: payload.quest_id,
      is_featured: !payload.is_featured,
      game_slug: payload.slug,
    }

    const res = await axiosClient.patch(url, data)

    return res.data
  } catch (error: any) {
    handleError(error)
    return false
  }
}

export const DownloadQuestReport = async (payload: IQuestEventsPayload) => {
  try {
    const url = `${serverConfig.questBaseURL}${serverConfig.downloadQuestReport}/download?file_type=${payload?.file_type}`

    const res = await axiosClient.post(url, payload)

    return res.data
  } catch (error: any) {
    handleError(error)
    return false
  }
}
